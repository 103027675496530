.mobile-menu div{
  width: 32px;
  height: 2px;
  background: #fff;
  margin: 8px;
  transition: 0.3s;  
}

.mobile-menu{
  cursor: pointer;
  display: none;
  display: block;
  margin-left: 30vw;
  margin-top: 2vh;
}




@media (min-width: 480px){
  .mobile-menu{
    display: none;
  }
}


.mobile-menu.active .line1 {
  transform: rotate(-45deg) translate(-8px, 8px);
}

.mobile-menu.active .line2 {
  opacity: 0;
}

.mobile-menu.active .line3 {
  transform: rotate(45deg) translate(-5px, -7px);
}
