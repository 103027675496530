 .video-responsive {
  /* overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; */
  width: 70vw;
  height: 40vw;
} 

 .video-responsive iframe {
  /* height: 80%;
  width: 80%;
  position: absolute; */
  width: 70vw;
  height: 40vw;
} 