@font-face {
  font-family:"GilroyBold";
  src: url("./fonts/Gilroy-Bold.ttf") format("truetype"); 
}

@font-face{
  font-family:"GilroySemiBold";
  src: url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face{
  font-family:"GilroyExtraBold";
  src: url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family:"GilroyHeavy";
  src: url("./fonts/Gilroy-Heavy.ttf") format("truetype");
}



.font-gb{
  font-family: "GilroyBold";
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

*  {
 
  margin: 0;
  font-family:"GilroySemiBold";
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span{
  font-family: GilroyExtraBold ;
  
}

*:focus {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
  scroll-behavior: smooth;
}

