.BrMap{  
  width: 90vh;
  height: 90vh;
}

.locations {
  fill: #0071c0;
  cursor: pointer;
  stroke: #365369;
  stroke-width: 1px;
}

.locations:hover {
  fill: #e0ba81;
  transition: 0.8s;
}

.locations:focus{
  fill: #f9ae42;
}